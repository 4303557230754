import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import * as RD from "@heyhabito/remote-data"

import {
  ErrorIcon,
  Body,
  CheckboxInput,
  EmailInputQuestion,
  GridRow,
  SecondaryTwoCTA,
  breakpoints,
  colours,
  column,
  gridSettings,
  horizontal,
  mkColumn,
  mkSubGridRow,
  TextLink,
  typographyStyles,
  vertical,
} from "design-kit"

import bg_newsletter from "../assets/bg_newsletter.jpg"
import { InlineIconWrapper } from "../icons/InlineWrapper"
import { EmojiCelebrate } from "../icons/EmojiCelebrate"

import { registerButtonClick } from "../../../utils/amplitude"

const subGridSettings = {
  desktop: gridSettings.desktop,
  tablet: gridSettings.tablet,
  mobile: gridSettings.mobile,
}

const SubGridRow = mkSubGridRow(breakpoints)(subGridSettings)
const subColumn = mkColumn(breakpoints)(subGridSettings)

const Background = styled.div`
  // Fallback in case the background image doesn't load
  background-color: #ec94e2;
  background-image: url(${bg_newsletter});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Container = styled.section`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}

  padding-top: ${vertical.l};
  padding-bottom: ${vertical.l};

  ${breakpoints.desktop`
    padding-top: ${vertical.xl};
    padding-bottom: ${vertical.xl};
  `}
`

const Titles = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 3,
    widthDesktop: 4,
    offsetDesktop: 1,
  })}
`

const EmptyColumn = styled.div`
  ${subColumn({
    widthDesktop: 1,
  })}

  display: none;

  ${breakpoints.desktop`
    display: block;
  `}
`

const Form = styled.form`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 3,
    widthDesktop: 5,
  })}
`

const Title = styled.h2`
  ${typographyStyles.headline1};
  margin-bottom: ${vertical.s};
`

const SuccessTitle = styled.h2`
  ${typographyStyles.statement2};
  margin-bottom: ${vertical.s};
`

const SuccessContainer = styled.div`
  ${subColumn({
    widthMobile: 4,
    widthTablet: 4,
    widthDesktop: 6,
    offsetDesktop: 1,
  })}
`

const Subtitle = styled.p`
  ${typographyStyles.headline};
  margin-bottom: ${vertical.m};
`

const Legend = styled.legend`
  ${typographyStyles.headline3};
  margin-bottom: ${vertical.xxs};
`

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.desktop`
    flex-direction: row;

    > :first-of-type {
      margin-right: ${horizontal.m};
    }
  `}
`

const ErrorMessage = styled(Body)`
  display: flex;
  justify-content: stretch;
  margin-bottom: ${vertical.xs};
`

const ErrorIconWrapper = styled.span`
  display: inline-block;
  margin-right: ${horizontal.xs};
`

const InputWrapper = styled.div`
  margin-bottom: ${vertical.s};

  label > span > span {
    ${typographyStyles.headline3};
  }
`

interface Data {
  _clientID: string
  _deExternalKey: string
  _action: string
  _returnXML: string
  _successURL: string
  _errorURL: string
  Homepage_Residential: boolean
  Homepage_BTL: boolean
  EmailAddress: string
}

export const NewsletterSignup: React.FC = () => {
  const [isResi, setResi] = useState<boolean>(false)
  const [isBtl, setBtl] = useState<boolean>(false)
  const [requiredError, setRequiredError] = useState<boolean>(false)
  const [userEmail, setUserEmail] = useState<string>("")

  const [status, setStatus] = useState<RD.RemoteData<string, string>>(
    RD.NotAsked
  )

  const data: Data = {
    _clientID: "100013183",
    _deExternalKey: "1A8BF4DA-1CE5-49D9-93AA-D6032FF2221E",
    _action: "add",
    _returnXML: "0",
    _successURL: "https://habito.com/?newsletter=success", // these need to be hardcoded because they are redirected from Salesforce
    _errorURL: "https://habito.com/?newsletter=fail", // same
    Homepage_Residential: isResi,
    Homepage_BTL: isBtl,
    EmailAddress: userEmail,
  }

  return (
    <Background>
      <GridRow>
        <Container id="sign-up-for-our-newsletter">
          <SubGridRow>
            {RD.isSuccess(status) ? (
              <SuccessContainer>
                <SuccessTitle>
                  Great{" "}
                  <InlineIconWrapper>
                    <EmojiCelebrate
                      color={colours.offBlack}
                      sizeMobile={48}
                      sizeDesktop={96}
                    />
                  </InlineIconWrapper>{" "}
                  , you're all signed up!
                </SuccessTitle>
                <Subtitle>Your inbox just got mortgage-y-er</Subtitle>
              </SuccessContainer>
            ) : (
              <React.Fragment>
                <Titles>
                  <Title>Sign up for our newsletter & other stuff</Title>
                  <Subtitle>
                    Get money-saving tips, special offers and new services,
                    straight to your inbox, around once a month.
                  </Subtitle>
                </Titles>

                <EmptyColumn />

                <Form
                  onSubmit={e => {
                    e.preventDefault()

                    if (!isBtl && !isResi) {
                      setRequiredError(true)
                      return
                    }

                    registerButtonClick("homepage")("Newsletter sign up")
                    setStatus(RD.Loading)

                    const formData = new FormData()
                    let name: keyof Data
                    for (name in data) {
                      formData.append(name, data[name].toString())
                    }

                    fetch("https://cl.exct.net/DEManager.aspx", {
                      method: "POST",
                      mode: "no-cors",
                      body: formData,
                    })
                      .then(() => setStatus(RD.Success("yes")))
                      .catch(() => setStatus(RD.Failure("no")))
                  }}
                >
                  <fieldset>
                    <Legend>I'm interested in:</Legend>

                    <Checkboxes>
                      <CheckboxInput
                        onChange={isChecked => {
                          if (isChecked) {
                            setRequiredError(false)
                          }
                          setResi(isChecked)
                        }}
                        checked={isResi}
                      >
                        Buying and remortgaging
                      </CheckboxInput>

                      <CheckboxInput
                        onChange={isChecked => {
                          if (isChecked) {
                            setRequiredError(false)
                          }
                          setBtl(isChecked)
                        }}
                        checked={isBtl}
                      >
                        Letting
                      </CheckboxInput>
                    </Checkboxes>

                    {requiredError && (
                      <ErrorMessage>
                        <ErrorIconWrapper aria-hidden>
                          <ErrorIcon />
                        </ErrorIconWrapper>
                        Please choose an option!
                      </ErrorMessage>
                    )}
                  </fieldset>

                  <InputWrapper>
                    <EmailInputQuestion
                      title="Your email address"
                      placeholder="Email address"
                      value={userEmail}
                      required
                      onInput={setUserEmail}
                      error={RD.isFailure(status) ? status.reason : undefined}
                    />
                  </InputWrapper>

                  <Body
                    css={css`
                      margin-bottom: ${vertical.m};
                    `}
                  >
                    By signing up, you agree we can email you about our
                    products, services, offers, news, insights, and research.
                    Unsubscribe any time. More in our{" "}
                    <TextLink
                      href="/privacy"
                      text="privacy policy"
                      colour="black"
                    />
                    .
                  </Body>

                  <SecondaryTwoCTA
                    onClick={() => {
                      return
                    }}
                    text="Sign up"
                    type="submit"
                    width={{
                      mobile: "full-width",
                      tablet: "full-width",
                      desktop: "content-width",
                    }}
                  />
                </Form>
              </React.Fragment>
            )}
          </SubGridRow>
        </Container>
      </GridRow>
    </Background>
  )
}
